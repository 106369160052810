import { useState, useEffect } from "react";
import { TableBody } from "./Table";
import { TableHeading } from "./Table";
import { tabIndexes } from "Constants/Patient";
import Table from "Components/Common/Table/Table";
import { usePatientContext } from "Context/Patient";
import {
  VISIT_FEEDBACK_VIEW,
  VISIT_NOTES_VIEW,
  VISIT_ORDER_VIEW,
  VISIT_TABLE_VIEW,
} from "./ConstantsViews";
import { Button } from "Components/Common/Button/Button";
import { VisitOrder } from "./VisitOrder";
import { VisitNotes } from "./VisitNotes";
import { VisitFeedback } from "./VisitFeedback";
import { patientVisitsFormatter } from "Helpers/Patients";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { GetVisitHistory } from "Services/API/Patients.service";
import { PaginationBtns } from "Components/Common/PaginationBtns/PaginationBtns";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import "Components/Patients/EditViewPatient/Forms/FormCommonCss/Form.css";
import "Colors.css";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";

export function VisitTable({ setActiveTabIndex }) {
  return (
    <>
      <div className="FormWrapper flex flex-col gap-6" style={{ overflow: 'auto' }}>
        <VisitActiveView />
      </div>
      <div className="flex justify-between">
        <Button
          btnText="Back"
          className="h-8 w-16 bg-white border-gray-300 sapphire"
          isDisabled={false}
          onClick={() => setActiveTabIndex(tabIndexes.MEDICAL_HISTORY)}
        />
        <Button
          btnText="Next"
          className="h-8 w-16 text-white royal_blue "
          isDisabled={false}
          onClick={() => setActiveTabIndex(tabIndexes.UPLOAD_FILE)}
        />
      </div>
    </>
  );
}

const VisitActiveView = () => {
  const { getPatientData } = usePatientContext();
  const [visitsActiveView, setVisitsActiveView] = useState(VISIT_TABLE_VIEW);
  const [patientVisits, setPatientVisits] = useState([]);
  const [patientId, setPatientId] = useState("")
  const [loading, setLoading] = useState(true);
  const [visitId, setVisitId] = useState("");
  const [docName, setDocName] = useState("");
  const [apiError, setApiError] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (getPatientData) {
      const { id } = getPatientData();
      setPatientId(id)
      if (id) {
        setLoading(true);
        GetVisitHistory(id)
          .then((res) => {
            setPatientVisits(patientVisitsFormatter(res?.data));
          })
          .catch((err) => {
            console.error("[Get_Patient_VISITS_History][API_ERROR} -", err);
            setApiError(
              errorFormatter(err?.response?.data?.errors || err?.message)
            );
          })
          .finally(() => {
            setLoading(false);
          });
          return () =>{
            setPatientVisits([]);

          }
      }
    }
  }, [getPatientData]);

  useEffect(() => {
    if (getPatientData) {
      const { id } = getPatientData();
      if (id) {
        setLoading(true);
        GetVisitHistory(id, pageNumber)
          .then((res) => {
            setLoading(false);
            if (res.data) {
              setPatientVisits(patientVisitsFormatter(res?.data));
              setHasNextPage(res?.pagination?.hasNext);
              setHasPreviousPage(res?.pagination?.hasPrevious);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.error(
              "[PATIENTS_VISITtABLE_ GetVISITSHistory][API_ERROR} -",
              err
            );
            setApiError(err?.response?.data?.errors || err?.message);
          });
      }
    }
  }, [getPatientData, pageNumber]);
  
  switch (visitsActiveView) {
    case VISIT_ORDER_VIEW:
      return (
        <VisitOrder
          setActiveView={setVisitsActiveView}
          patientId={patientId}
          visitId={visitId}
          docName={docName}
        />
      );
    case VISIT_NOTES_VIEW:
      return (
        <VisitNotes
          setActiveView={setVisitsActiveView}
          patientId={patientId}
          visitId={visitId}
          docName={docName}
        />
      );
    case VISIT_FEEDBACK_VIEW:
      return (
        <VisitFeedback
          setActiveView={setVisitsActiveView}
          patientId={patientId}
          visitId={visitId}
          docName={docName}
        />
      );
    default:
      return (
        <div className="h-full">
          {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}

          <Table
            heading={<TableHeading />}
            body={
              patientVisits.length > 0 ? (
                <TableBody
                  data={patientVisits}
                  onClick={({ id, docName }, nextActiveView) => {
                    setVisitId(id);
                    setDocName(docName);
                    setVisitsActiveView(nextActiveView);
                  }}
                />
              ) : (
                !loading && "No Data Found"
              )
            }
          />
          {loading && <Spinner />}
          {!loading && patientVisits.length > 0 && (
            <PaginationBtns
              isNextDisabled={!hasNextPage}
              isPrevDisabled={!hasPreviousPage}
              onNext={() => {
                if (hasNextPage) {
                  setPageNumber(pageNumber + 1);
                }
              }}
              onPrevious={() => {
                if (hasPreviousPage) {
                  setPageNumber(pageNumber - 1);
                }
              }}
            />
          )}
        </div>
      );
  }
};
