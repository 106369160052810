import { formatDate, formatDate2, formatBirthDate } from "./DateFormat";

export const formatPhone = (value) => {
  let result = null
  try {
    result = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`
  } catch (e) {}
  return result
}

export function formatPatientStatus(status) {
  switch (status) {
    case "Disabled":
      return "Deactivated";
    case "Enabled":
      return "Activated";
    default:
      return "";
//      throw new Error(
//        `Unregistered status "${status}" found in patient record`,
//      );
  }
}

export const formatPatient = (data) => {
  return {
    id: data?.id,
    name: data?.name?.replaceAll("-","") || "",
    firstName: data?.firstName || "-",
    lastName: data?.lastName || "-",
    userName: data?.username || "",
    gender: data?.gender || "-",
    phoneNumber: formatPhone(data?.phoneNumber) || "-",
    country: data?.country || "-",
    zipCode: data?.zipCode || "-",
    dosespotId: data?.dosespotId || "",
    cognitoId: data?.cognitoId || "",
    memberShipPlan: data?.membership?.name || "-",
    streetAddress: data?.address || "-",
    state: data?.state?.name || "- - -",
    stateId: data?.state?.id || "",
    DOB: formatBirthDate(data?.dateOfBirth) || "-",
    image: data?.image || "",
    actionStatus: formatPatientStatus(data?.status) || "",
    city: data?.city || "-",
    address1: data?.address1 || "-",
    address2: data?.address2 || "-",
    prefix: data?.prefix || "-",
    suffix: data?.suffix || "-",
    middleName: data?.middleName || "-",
    phoneType: data?.primaryPhoneType || "-",
  };
}

export const patientFormatterList = (data) => {
  const formattedResult = data.map((item) => {
    return formatPatient(item)
  });
  return formattedResult;
};

export const patientMedicationsFormatter = (data) => {
  const formattedResult = data.map((list) => {
    return {
      id: list?.id,
      medicineName: list?.medicationId.name || "",
      dosage: list?.dosage || "",
      medicineUsages: list?.frequency || "",
    };
  });
  return formattedResult;
};
export const getMedicalHistoryFormatter = ({
  bmi,
  alcohol,
  tobacco,
  sexualHistory,
  userAllergies,
  familyHistory,
  surgicalHistory,
  immunizationHistory,
  userImmunization,
  userSurgical,
}) => {
  const formatFamilyHistory = (familyHistory) => {
    return familyHistory?.map((history) => {
      return history?.name;
    });
  };
  const formatImmunization = (immunizationHistory) => {
    return immunizationHistory?.map((immuniztion) => {
      return immuniztion?.name;
    });
  };
  const formatSurgicalHistory = (surgicalHistory) => {
    return surgicalHistory?.map((surgery) => {
      return surgery?.name;
    });
  };
  const formatUserAllergy = (userAllergy) => {
    return userAllergy?.map((allergy) => {
      return {
        name: allergy?.allergyId.name || "-",
        type: allergy?.allergyId.type || "-",
        reaction: allergy?.reaction || "-",
      };
    });
  };
  const formatUserImmunization = (userImmunization) => {
    const formatImmunizationForm = (immunizations) => {
      return immunizations?.map((immunization) => {
        return {
          id: immunization.id,
          name: immunization.name,
          subName: immunization?.children?.map((child) => {
            if (child.selected) {
              return { id: child?.id, name: child.name };
            }
          }),
        };
      });
    };
    return {
      id: userImmunization.id,
      immuniztionName: formatImmunizationForm(
        userImmunization?.immunizationForm,
      ),
    };
  };
  const formatUserSurgical = (userSurgical) => {
    return userSurgical?.custom?.map((surgery) => {
      return { name: surgery?.name ?? "" };
    });
  };
  return {
    heightFeet: bmi?.feet ?? "-",
    heightInches: bmi?.inches ?? "-",
    weight: bmi?.weightLbs ?? "-",
    allergies: formatUserAllergy(userAllergies) || "-",
    alcoholConsumption: alcohol || "-",
    tobacoPerWeek: tobacco?.tobaccoPerWeek || "",
    tobacoAnnual: tobacco?.tobaccoYears || "",
    surgicalHistory: formatSurgicalHistory(surgicalHistory) || "",
    familyHistory: formatFamilyHistory(familyHistory) || "-",
    immunizationHistory: formatImmunization(immunizationHistory) || "",
    userImmunization: formatUserImmunization(userImmunization) || "",
    userSurgical: formatUserSurgical(userSurgical) || "",
    sexualHistory: sexualHistory ? "Yes" : "No",
  };
};

export const patientVisitsFormatter = (data) => (
  data.map((item) => ({
    id: item?._id,
    status: item.status,
    states: item.states,
    offerActiveMinutes: item.offerActiveMinutes ? `${item.offerActiveMinutes} mins` : 'N/A',
    docName: item?.doctorFirstName && item?.doctorLastName ? `${item.doctorFirstName} ${item.doctorLastName}` : 'None',
    doctorUsername: item?.doctorUsername || '',
    createdAt: formatDate2(item?.createdAt),
    startedAt: formatDate2(item?.startedAt),
    endedAt: formatDate2(item?.endedAt),
    waitStartedAt: formatDate2(item?.waitStartedAt),
    waitEndedAt: formatDate2(item?.waitEndedAt),
    lastMessage: item?.lastMessage,
  }))
)
