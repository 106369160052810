import { useState, useEffect } from "react";
import { Header } from "./Header";
import { formatDate } from "Helpers/DateFormat";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import formatPhoneNumber from "Helpers/PhoneNumber";
import { GetVisitOrders } from "Services/API/Patients.service";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { PaginationBtns } from "Components/Common/PaginationBtns/PaginationBtns";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";

// STATUS
const getPrescriptionStatus = (status) => {
  const statusMap = {
    "Entered": 1,
    "Printed": 2,
    "Sending": 3,
    "eRxSent": 4,
    "FaxSent": 5,   // Deprecated
    "Error": 6,
    "Deleted": 7,
    "Requested": 8,
    "Edited": 9,
    "EpcsError": 10,
    "EpcsSigned": 11,
    "ReadyToSign": 12,
    "PharmacyVerified": 13
  }
  let result
  Object.keys(statusMap).map(i => {
    if (statusMap[i] === status) {
      result = i
    }
  })
  return result
}

// MEDICATION STATUS
const getMedicationStatus = (status) => {
  const medicationStatusMap = {
    "Unknown": 0,       // Deprecated
    "Active": 1,
    "Inactive": 2,      // Deprecated
    "Discontinued": 3,
    "Deleted": 4,
    "Completed": 5,
    "CancelRequested": 6,
    "CancelPending": 7,
    "Cancelled": 8,
    "CancelDenied": 9,
    "Changed": 10,
    "FullFill": 11,
    "PartialFill": 12,
    "NoFill": 13
  }
  let result
  Object.keys(medicationStatusMap).map(i => {
    if (medicationStatusMap[i] === status) {
      result = i
    }
  })
  return result
}

const ordersFormatter = (orders) => {
  return orders?.map((order) => {
    return {
      id: order.id,
      medName: order.genericDrugName || order.displayName,
      frequency: order.directions,
      dosage: order.strength,
      daysSupply: order.daysSupply,
      quantity: order.quantity,
      strength: order.strength,
      pharmacy: order.pharmacy?.Item?.StoreName,
      address1: order.pharmacy?.Item?.Address1,
      address2: order.pharmacy?.Item?.Address2,
      city: order.pharmacy?.Item?.City,
      state: order.pharmacy?.Item?.State,
      zip: order.pharmacy?.Item?.ZipCode,
      phone: formatPhoneNumber(order.pharmacy?.Item?.PrimaryPhone),
      date: formatDate(order.createdAt, "MM-DD-YYYY") || "",
      time: formatDate(order.createdAt, "HH:mm A") || "",
      medicationStatus: getMedicationStatus(order.medicationStatus),
      status: getPrescriptionStatus(order.status),
    };
  });
};
const SUB_HEADING_CSS = "text-sm ml-4 leading-4 font-normal sapphire";

export const VisitOrder = ({ setActiveView, visitId,patientId, docName,patientOrders }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (patientId && visitId) {
      setLoading(true);
      GetVisitOrders(patientId, { visitId },pageNumber)
        .then((res) => {
          setOrders(ordersFormatter(res?.data));
          setHasNextPage(res?.pagination?.hasNext);
          setHasPreviousPage(res?.pagination?.hasPrevious);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error("[GetVisitOrders][API_ERROR] - ", err);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message)
          );
        });
    }
  }, [patientId, visitId,pageNumber]);
  useEffect(() => {
    setOrders(patientOrders);
    setLoading(false);
  },[JSON.stringify(patientOrders)])
  return (
    <>
      {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}
      <Header onBack={setActiveView} title={docName}></Header>
      <span className="light_grayish_navy text-md p-2">Orders</span>
      {orders?.length > 0
        ? orders?.map((order, index) => (
          <div key={index} className="flex flex-col gap-4 p-2">
            <span className="text-sm leading-4 font-normal sapphire">
              {index + 1}. {order.medName || order.displayName}
            </span>

            <span className={SUB_HEADING_CSS}><strong>Prescription Status: {order.status}</strong></span>
            <span className={SUB_HEADING_CSS}><strong>Medication Status: {order.medicationStatus}</strong></span>

            <span className={SUB_HEADING_CSS}>Dosage: {order.dosage || order?.strength}</span>
            <span className={SUB_HEADING_CSS}>Quantity: {order.quantity}</span>
            <span className={SUB_HEADING_CSS}>
              Frequency: {order.frequency}
            </span>
              <span className={SUB_HEADING_CSS}>
                Pharmacy: {order?.pharmacy}
              </span>
              <span className={SUB_HEADING_CSS}>
                {order.address1}
                <div>{order.address2}</div>
                <div>{order.address2}</div>
                <div>{order.city}, {order.state}</div>
                <div>{order.zip}</div>
                <div>{formatPhoneNumber(order.phone)}</div>
              </span>
              <div className="flex flex-col gap-1 ml-4 text-sm light_grayish_navy">
                <span>Date: {order.date}</span>
                <span>Time: {order.time}</span>
                <span>Provider: {docName}</span>
              </div>
            </div>
          ))
        : !loading && "No Order Found"}

      {loading && <Spinner />}
      {!loading && orders?.length > 0 && (
        <PaginationBtns
          isNextDisabled={!hasNextPage}
          isPrevDisabled={!hasPreviousPage}
          onNext={() => {
            if (hasNextPage) {
              setPageNumber(pageNumber + 1);
            }
          }}
          onPrevious={() => {
            if (hasPreviousPage) {
              setPageNumber(pageNumber - 1);
            }
          }}
        />
      )}
    </>
  );
};
