import { useState, useEffect } from "react";
import { useQuery } from '@tanstack/react-query'
import Axios from "Services/Auth/AxiosInterceptors";
import { EDIT_PATIENT_PATH } from "Constants/Views";
import { InputField } from "Components/Common/InputField/InputField";
import { Button } from "Components/Common/Button/Button";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";
import "Colors.css";

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "mt-4 text-sm leading-4 font-medium sapphire";
const TITLE_CSS = "light_grayish_navy text-left font-medium"

function VerifyCodes() {
  const [email, setEmail] = useState('');
  const [apiError, setApiError] = useState('');

  const {
    data,
    reset,
    refetch,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: ['verifyCode'],
    queryFn: () => Axios.post('/admin/patient/verify-code/get', { email }),
    enabled: false,
  })

  const code = data?.data?.result?.authCode
  const emailVerified = data?.data?.result?.emailVerified
  const error = data?.data?.errors?.[0]

  // console.log('DEBUG >>>>>>>>>>>', { isError, error, result: data?.data?.result })

  return (
    <div className="p-4 flex flex-wrap gap-4 justify-between" style={{ width: '350px', display: 'block' }}>
      <div>
        {!isSuccess && !isError && <div className={TITLE_CSS}>Lookup patient's verification code</div>}

        {(isSuccess && emailVerified) && (
        <div className="green text-left font-medium">
          {email} was verified!
        </div>
        )}
        {(isSuccess && !emailVerified) && (
          <div className="text-red-800 text-left font-medium">
            Email not verified!
          </div>
        )}
        {isError && (
          <div className="text-red-800 text-left font-medium">
            Email was not found
          </div>
        )}

        {isError && <ErrorPopUp error={error} setApiError={() =>{}} />}


        {!isError && code && <div className={SUB_HEADING_CSS}>Verification Code: {code}</div>}

        <div className="lg:space-y-2 2xl:space-y-4 mt-8">
          <InputField
            placeholder="Patient Email"
            isRequired={true}
            value=""
            onChange={(value) => setEmail(value)}
          />
          <Button
            className="w-full py-2"
            btnText="Search"
            isDisabled={false}
            isLoading={isFetching}
            onClick={() => refetch()}
          />
        </div>
      </div>

      <div className="mt-12 p-4 light_grayish_navy text-left" style={{ borderRadius: '10px', background: '#eee', fontSize: '14px' }}>
        Tip: If patient leaves Email Verification Screen, a new code may be generated. Click "Search" to make sure you have the latest code to give the patient.
      </div>

    </div>
  );

}

export default VerifyCodes
