/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ModalBasic from "Components/Modal/Modal";
import Table from "Components/Common/Table/Table";
import {
  EDIT_PATIENT_PATH,
  PATIENT_ACCESS_LOGS,
  BASE_PATIENT_PATH,
  PATIENT_REFUNDS_PATH,
  PATIENT_VERIFY_PATH,
} from "Constants/Views";
import { formatPatientStatus, patientFormatterList } from "Helpers/Patients";
import { usePatientContext } from "Context/Patient";
import { DROPDOWN_OPTIONS } from "Constants/DropDown";
import MainLayout from "Layouts/MainLayout/MainLayout";
import {
  GetPatientById,
  PatientActivate,
  PatientDeactivate,
} from "Services/API/Patients.service";
import SearchBar from "Components/Common/SearchBar/SearchBar";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { ReactComponent as ProfileImage } from "Assets/Profile.svg";
import { ReactComponent as DeactivateIcon } from "Assets/Deactivate.svg";
import { ReactComponent as ActivateIcon } from "Assets/Activate.svg";
import { ReactComponent as PasswordIcon } from "Assets/PatientPassword.svg";
import { ReactComponent as FilterIcon } from "Assets/Filter.svg";
import { FormattedDropdown } from "Components/Common/FormattedDropdown/FormattedDropDown";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";
import { PaginationBtns } from "Components/Common/PaginationBtns/PaginationBtns";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { UserAuthenticationModal } from "./UserModal";
import { SearchPatientByFilters } from "Services/API/Search.service";
import { useParams } from "react-router-dom";
import { Button } from "Components/Common/Button/Button";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { ReactComponent as SadFaceIcon } from "Assets/SadFace.svg";
import "Colors.css";
import { formatDate } from "Helpers/DateFormat";
import ClassNames from "Helpers/Common";
import OfferRefunds from "./OfferRefunds";
import VerifyCodes from "./VerifyCodes";
import { formatBirthDate } from "Helpers/DateFormat";

const TABLE_HEADINGS = ["Name", "State", "Date of Birth", "Actions ", " ", ""];
const NAME = "name";
const USERNAME = "username";
const PHONE_NUMBER = "phoneNumber";
const ID = "cognitoId";

const FILTERS_INIT_STATE = {
  name: true,
  userName: false,
  phoneNumber: false,
  id: false,
};

export function PatientsMainPage() {
  const { patient } = useParams();
  const isPatientsTab = patient === ':patient'
  const isRefundsTab = patient === 'refunds'
  const isVerifyTab = patient === 'verify'

  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(true);
  const [showPatientsTable, setShowPatientsTable] = useState(false);
  const [searchedPatientResult, setSearchesPatientResult] = useState({});
  const [countDown, setCountDown] = useState(0);
  const [selectedFilterBy, setSelectedFilterBy] = useState(FILTERS_INIT_STATE);
  const [dobTo, setDobTo] = useState("");
  const [dobFrom, setDobFrom] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const fetchPatients = () => {
    setLoading(true);
    SearchPatientByFilters(
      {
        searchCriteria: selectedFilterBy?.userName
          ? USERNAME
          : selectedFilterBy?.phoneNumber
          ? PHONE_NUMBER
          : selectedFilterBy?.id
          ? ID
          : NAME,
        query: searchQuery,
        dob_from: dobFrom ? dobFrom : null,
        dob_to: dobTo ? dobTo : null,
      },
      pageNumber,
    )
      .then((res) => {
        if (res) {
          setSearchResults([]);
          setPatients(patientFormatterList(res?.data));
          setHasNextPage(res?.pagination?.hasNext);
          setHasPreviousPage(res?.pagination?.hasPrevious);
          setLoading(false);
        } else if (!res) {
          setSearchResults([]);
          setPatients([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("[SearchPatientByFilters][API_ERROR] - ", err);
      });

    return () => {
      setPatients([]);
    };
  };

  useEffect(() => {
    let timerId;

    if (showPatientsTable) {
      setCountDown(60 * 3);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [showPatientsTable]);
  useEffect(() => {
    if (showPatientsTable) {
      function handleKeyDown(e) {
        setCountDown(60 * 3);
      }

      document.addEventListener("keydown", handleKeyDown);

      // Don't forget to clean up
      return function cleanup() {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [showPatientsTable]);
  useEffect(() => {
    if (countDown === 0) {
      setIsAuthModalOpen(true);
      setShowPatientsTable(false);
    } else {
      var timer;
      var timeout = function () {};
      timer = setTimeout(timeout, 5000);
      window.onmousemove = function () {
        setCountDown(60 * 3);
        timer = setTimeout(timeout, 5000);
      };
    }
  }, [countDown]);
  useEffect(() => {
    if (showPatientsTable) {
      fetchPatients();
    }
  }, [pageNumber, showPatientsTable]);
  useEffect(() => {
    if (patient === "PatientDetail") {
      setIsAuthModalOpen(false);
      setShowPatientsTable(true);
    }
  }, [patient]);

  const HeaderProps = {
    setPatients,
    fetchPatients,
    setHasNextPage,
    searchedPatientResult,
    setSearchesPatientResult,
    setSearchResults,
    searchResults,
    setHasPreviousPage,
    dobTo,
    dobFrom,
    setDobTo,
    setDobFrom,
    selectedFilterBy,
    setSelectedFilterBy,
    searchQuery,
    setSearchQuery,
    pageNumber,
    setPageNumber,
    isPatientsTab,
    isRefundsTab,
    isVerifyTab,
  };
  // console.log("Patients are", patients);
  return (
    <MainLayout>
      <UserAuthenticationModal
        modalOpen={isAuthModalOpen}
        icon={<PasswordIcon />}
        btnText="Continue"
        heading="Re-enter Password"
        content="To access patient information, please enter your password"
        setShowPatientsTable={setShowPatientsTable}
        setIsAuthModalOpen={setIsAuthModalOpen}
      />
      {(showPatientsTable && isPatientsTab) && (
        <div className="p-8 flex flex-col gap-8">
          {apiError && (
            <ErrorPopUp error={apiError} setApiError={setApiError} />
          )}
          <Header {...HeaderProps} />
          {!loading && patients.length <= 0 ? (
            <div className="flex flex-col items-center justify-center h-96">
              <SadFaceIcon className="h-16 w-16  animate-bounce text-red-800" />
              <p className="mt-1 text-md font-medium animate-pulse text-red-800">
                Sorry no data found
              </p>
            </div>
          ) : (
            patients?.length > 0 && (
              <Table
                heading={<TableHeading />}
                body={
                  !loading &&
                  patients?.length > 0 && (
                    <TableBody
                      data={patients}
                      setApiError={setApiError}
                      fetchPatients={fetchPatients}
                      searchResults={searchedPatientResult}
                    />
                  )
                }
              />
            )
          )}

          {loading && <Spinner />}

          {(!loading && patients.length > 0 && isPatientsTab) && (
            <PaginationBtns
              isNextDisabled={!hasNextPage}
              isPrevDisabled={!hasPreviousPage}
              onNext={() => {
                if (hasNextPage) {
                  setPageNumber(pageNumber + 1);
                }
              }}
              onPrevious={() => {
                if (hasPreviousPage) {
                  setPageNumber(pageNumber - 1);
                }
              }}
            />
          )}
        </div>
      )}

      {(showPatientsTable && isRefundsTab) && (
        <div className="p-8 flex flex-col gap-8">
          <Header {...HeaderProps} />
          <OfferRefunds />
        </div>
      )}

      {(showPatientsTable && isVerifyTab) && (
        <div className="p-8 flex flex-col gap-8">
          <Header {...HeaderProps} />
          <VerifyCodes />
        </div>
      )}

    </MainLayout>
  );
}
const Header = ({
  setPatients,
  fetchPatients,
  setHasNextPage,
  searchedPatientResult,
  setSearchesPatientResult,
  setSearchResults,
  searchResults,
  setHasPreviousPage,
  dobTo,
  dobFrom,
  setDobTo,
  setDobFrom,
  selectedFilterBy,
  setSelectedFilterBy,
  searchQuery,
  setSearchQuery,
  pageNumber,
  setPageNumber,
  isPatientsTab,
  isRefundsTab,
  isVerifyTab,
}) => {
  const [filterView, setFilterView] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);

  const patientsSearch = (enterPressed = false) => {
    SearchPatientByFilters(
      {
        searchCriteria: selectedFilterBy?.userName
          ? USERNAME
          : selectedFilterBy?.phoneNumber
          ? PHONE_NUMBER
          : selectedFilterBy?.id
          ? ID
          : NAME,
        query: searchQuery,
        dob_from: dobFrom ? dobFrom : null,
        dob_to: dobTo ? dobTo : null,
      },
      pageNumber,
    )
      .then((res) => {
        if (res && !enterPressed) {
          setSearchResults([...res?.data]);
          setPatients(patientFormatterList(res?.data));
          setHasNextPage(res?.pagination?.hasNext);
          setHasPreviousPage(res?.pagination?.hasPrevious);
        } else if (res && enterPressed) {
          setSearchResults([]);
          setPatients(patientFormatterList(res?.data));
          setHasNextPage(res?.pagination?.hasNext);
          setHasPreviousPage(res?.pagination?.hasPrevious);
        } else if (!res) {
          setSearchResults([]);
          setPatients([]);
        }
      })
      .catch((err) => {
        console.error("[SearchPatientByFilters][API_ERROR] - ", err);
      });
    return () => {
      setSearchResults([]);
      if (enterPressed) {
        setPatients([]);
      }
    };
  };
  useEffect(() => {
    if (searchQuery.length >= 2 || dobTo || dobFrom) {
      patientsSearch();
    } else if (searchQuery.length === 0) {
      fetchPatients();
    }
  }, [searchQuery, applyFilter, dobTo, dobFrom]);

  return (
    <div className="flex justify-between items-center ">
      <div className="flex items-center gap-6 mr-5">
        {" "}
        <span className="font-semibold text-2xl sapphire">Patients</span>
        <Link to={BASE_PATIENT_PATH}>
          <span
            className={ClassNames(
              "light_grayish_navy text-sm font-medium sapphire cursor-pointer text-center mt-1 py-2 px-2 rounded-md",
              isPatientsTab && "bg-gray-100 font-semibold text-black"
              )}
            >
            Patients
          </span>
        </Link>
        <Link to={PATIENT_REFUNDS_PATH}>
          <span
            className={ClassNames(
              "light_grayish_navy text-sm font-medium cursor-pointer mt-1 py-2 px-2 rounded-md",
              isRefundsTab ? "bg-gray-100 font-semibold text-black" : ""
              )}
            >
            Offer Refunds
          </span>
        </Link>
        <Link to={PATIENT_VERIFY_PATH}>
          <span
            className={ClassNames(
              "light_grayish_navy text-sm font-medium cursor-pointer mt-1 py-2 px-2 rounded-md",
              isVerifyTab ? "bg-gray-100 font-semibold text-black" : ""
              )}
            >
            Verification Codes
          </span>
        </Link>
      </div>
      {isPatientsTab && (
        <div className="flex items-center justify-end gap-3 w-3/3">
          <div className="w-80 h-full">
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              searchResults={!filterView ? searchResults : []}
              setSearchResults={setSearchResults}
              isFiltered={true}
              filterKey={Object.keys(selectedFilterBy).filter(
                (key) => selectedFilterBy[key],
                )}
              onKeyDown={(e) => {
              let enterPressed = false;
              if (e.key === "Enter" && searchQuery.length >= 2) {
                patientsSearch((enterPressed = true));
              }
            }}
              onSearch={(searchedPatientid) => {
              if (searchedPatientid) {

                // get single patient details

                GetPatientById(searchedPatientid)
                  .then((res) => {
                    setSearchesPatientResult({
                      id: res?.id,
                      name: res?.name || "",
                      firstName: res?.firstName || "-",
                      lastName: res?.lastName || "-",
                      userName: res?.username || "",
                      gender: res?.gender || "-",
                      phoneNumber: res?.phoneNumber || "-",
                      country: res?.country || "-",
                      zipCode: res?.zipCode || "-",
                      memberShipPlan: res?.membership?.name || "-",
                      streetAddress: res?.address || "-",
                      state: res?.state?.name || "- - -",
                      stateId: res?.state?.id || "",
                      DOB: formatBirthDate(res?.dateOfBirth) || "-",
                      image: res?.image || "",
                      actionStatus: formatPatientStatus(res?.status) || "",
                      city: res?.city || "-",
                      address1: res?.address1 || "-",
                      address2: res?.address2 || "-",
                      prefix: res?.prefix || "-",
                      suffix: res?.suffix || "-",
                      middleName: res?.middle_name || "-",
                      phoneType: res?.primaryPhoneType || "-",
                      cognitoId: res?.cognitoId || "",
                    });
                  })
                  .catch((err) => {
                    console.error(
                      "[GetPatientById][API_ERROR][SEARCH] - ",
                      err,
                      );
                  });
              }
            }}
            />
          </div>
          <Button
            variant="transparent"
            className="bg-white text-base font-normal sapphire border border-gray-200 py-2 px-4 inline-flex rounded-md w-28 h-8 relative"
            btnText="Filters"
            isDisabled={false}
            onClick={() => setFilterView((prevState) => !prevState)}
            >
            <FilterIcon className="h-5 w-5 text-black" />
          </Button>
          {filterView && (
            <FilterView
              dobTo={dobTo}
              dobFrom={dobFrom}
              setDobTo={setDobTo}
              setDobFrom={setDobFrom}
              searchQuery={searchQuery}
              setFilterView={setFilterView}
              setApplyFilter={setApplyFilter}
              selectedFilterBy={selectedFilterBy}
              setSelectedFilterBy={setSelectedFilterBy}
              setPageNumber={setPageNumber}
            />
            )}
        </div>
      )}
    </div>
  );
};

const TableHeading = () => {
  return TABLE_HEADINGS.map((head, index) => (
    <th
      key={index}
      scope="col"
      className="bali_hai px-6 py-3 text-left text-sm font-medium"
    >
      {head}
    </th>
  ));
};

const TableBody = ({ data, setApiError, fetchPatients, searchResults }) => {
  const { setActivePatient, setActiveView, setGeneralInfo } =
    usePatientContext();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const setPatientView = id => {
    setActiveView(EDIT_PATIENT_PATH.replace(':id', id));
  }

  return (
    <>
      {!searchResults?.id
        ? data?.map((patient, index) => (
            <tr key={index}>
              <td
                className="px-6 py-4 whitespace-nowrap"
                onClick={() => {
                  setActivePatient({
                    name: patient?.firstName + " " + patient?.lastName,
                    id: patient.id,
                  });
                  setGeneralInfo(patient);
                  setPatientView(patient.id);
                }}
              >
                <div className="flex items-center">
                  <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100 cursor-pointer">
                    {patient.image ? (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={patient.image}
                      />
                    ) : (
                      <ProfileImage />
                    )}
                  </span>
                  <div className="ml-4">
                    <span className="text-sm font-medium eclipse cursor-pointer">
                      {patient.firstName}{' '}
                      {patient.lastName}
                    </span>
                    <br />
                    <span className="bali_hai text-sm cursor-pointer">
                      {patient.userName}
                    </span>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm bali_hai">{patient.state}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm bali_hai">
                {patient.DOB}
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm w-64 text-gray-500">
                <FormattedDropdown
                  status={patient.actionStatus}
                  dropDownOptions={DROPDOWN_OPTIONS}
                  onClick={(selectedOption) => {
                    setSelectedUserEmail(patient.userName);
                    setModal(true);
                    setSelectedOption(selectedOption);
                  }}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  onClick={() => {
                    setActivePatient({
                      name: patient?.firstName + " " + patient?.lastName,
                      id: patient.id,
                    });
                    setGeneralInfo(patient);
                    setPatientView(patient.id);
                  }}
                  className="text-blue-chill hover:text-blue-light cursor-pointer"
                >
                  Edit
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  onClick={() => {
                    setActivePatient({
                      name: patient?.firstName + " " + patient?.lastName,
                      id: patient.id,
                    });

                    setActiveView(PATIENT_ACCESS_LOGS);
                  }}
                  className="text-blue-chill hover:text-blue-light cursor-pointer"
                >
                  View Access Log
                </span>
              </td>
            </tr>
          ))
        : searchResults?.id && (
            <tr key={searchResults?.id}>
              <td
                className="px-6 py-4 whitespace-nowrap"
                onClick={() => {
                  setActivePatient({
                    name:
                      searchResults?.firstName + " " + searchResults?.lastName,
                    id: searchResults?.id,
                  });
                  setGeneralInfo(searchResults);
                  setPatientView(searchResults?.id);
                }}
              >
                <div className="flex items-center">
                  <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100 cursor-pointer">
                    {searchResults?.image ? (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={searchResults?.image}
                      />
                    ) : (
                      <ProfileImage />
                    )}
                  </span>
                  <div className="ml-4">
                    <span className="text-sm font-medium eclipse cursor-pointer">
                      {`${searchResults?.firstName} ${searchResults?.lastName}`}
                    </span>
                    <br />
                    <span className="bali_hai text-sm cursor-pointer">
                      {searchResults?.userName}
                    </span>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm bali_hai">{searchResults?.state}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm bali_hai">
                {searchResults?.DOB}
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <FormattedDropdown
                  status={searchResults?.actionStatus}
                  dropDownOptions={DROPDOWN_OPTIONS}
                  onClick={(selectedOption) => {
                    setSelectedUserEmail(searchResults?.userName);
                    setModal(true);
                    setSelectedOption(selectedOption);
                  }}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  onClick={() => {
                    setActivePatient({
                      name:
                        searchResults?.firstName +
                        " " +
                        searchResults?.lastName,
                      id: searchResults?.id,
                    });
                    setGeneralInfo(searchResults);
                    setPatientView(searchResults?.id);
                  }}
                  className="text-blue-chill hover:text-blue-light cursor-pointer"
                >
                  Edit
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  onClick={() => {
                    setActivePatient({
                      name:
                        searchResults?.firstName +
                        " " +
                        searchResults?.lastName,
                      id: searchResults?.id,
                    });

                    setActiveView(PATIENT_ACCESS_LOGS);
                  }}
                  className="text-blue-chill hover:text-blue-light cursor-pointer"
                >
                  View Access Log
                </span>
              </td>
            </tr>
          )}
      {modal && selectedOption === "Deactivate" ? (
        <ModalBasic
          open={modal}
          icon={<DeactivateIcon />}
          btnText="Deactivate"
          loading={isLoading}
          heading="Deactivate Profile"
          content="Are you sure you want to deactivate this profile?"
          //TODO Content confirmation with UX
          setOpen={(value) => {
            if (value === "Deactivate") {
              setIsLoading(true);
              PatientDeactivate({ email: selectedUserEmail })
                .then((res) => {
                  setSelectedOption("");
                  setIsLoading(false);
                  setModal(false);
                  fetchPatients();
                })
                .catch((err) => {
                  setSelectedOption("");
                  setIsLoading(false);
                  setModal(false);
                  setApiError(err?.response?.data?.errors);
                  console.error("[PatientDeactivate][API_ERROR] - ", err);
                });
            } else {
              setModal(false);
            }
          }}
        />
      ) : selectedOption === "Activate" ? (
        <ModalBasic
          open={modal}
          icon={<ActivateIcon />}
          btnText="Activate"
          loading={isLoading}
          heading="Activate Profile"
          content="Are you sure you want to Activate this profile?"
          //TODO Content confirmation with UX
          setOpen={(value) => {
            if (value === "Activate") {
              setIsLoading(true);
              PatientActivate({ email: selectedUserEmail })
                .then((res) => {
                  setSelectedOption("");
                  setIsLoading(false);
                  setModal(false);
                  fetchPatients();
                })
                .catch((err) => {
                  setSelectedOption("");
                  setIsLoading(false);
                  setModal(false);
                  setApiError(err?.response?.data?.errors);
                  console.error("[PatientActivate][API_ERROR] - ", err);
                });
            } else {
              setModal(false);
            }
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

const FilterView = ({
  dobTo,
  dobFrom,
  setDobFrom,
  setDobTo,
  searchQuery,
  setFilterView,
  setApplyFilter,
  selectedFilterBy,
  setSelectedFilterBy,
  setPageNumber,
}) => {
  return (
    <div className="flex flex-col p-2 gap-2 w-1/3  bg-white absolute mt-72 z-50 shadow-lg rounded-md">
      <fieldset className="mt-4 space-y-6">
        <div className="space-y-2">
          <RadioButton
            id="1"
            label="Name"
            className="text-sm font-medium"
            value={NAME}
            isSelected={!!selectedFilterBy?.name}
            isDisabled={!!/^[0-9]+$/.test(searchQuery)}
            onChange={() => {
              setSelectedFilterBy({ ...FILTERS_INIT_STATE, name: true });
            }}
          />
          <RadioButton
            id="2"
            label="Email"
            className="text-sm font-medium"
            value={USERNAME}
            isDisabled={false}
            isSelected={!!selectedFilterBy?.userName}
            onChange={() => {
              setSelectedFilterBy({
                ...FILTERS_INIT_STATE,
                name: false,
                userName: true,
              });
            }}
          />
          <RadioButton
            id="3"
            label="Phone Number"
            className="text-sm font-medium"
            value={PHONE_NUMBER}
            isSelected={!!selectedFilterBy?.phoneNumber}
            isDisabled={!!/^[a-zA-Z]+$/.test(searchQuery)}
            onChange={() => {
              setSelectedFilterBy({
                ...FILTERS_INIT_STATE,
                name: false,
                phoneNumber: true,
              });
            }}
          />{" "}
          <RadioButton
            id="4"
            label="Id"
            className="text-sm font-medium"
            value={ID}
            isSelected={!!selectedFilterBy.id}
            isDisabled={!!/^[a-zA-Z]+$/.test(searchQuery)}
            onChange={() => {
              setSelectedFilterBy({
                ...FILTERS_INIT_STATE,
                name: false,
                id: true,
              });
            }}
          />
          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium">Date of Birth</span>
            <div className="flex gap-2">
              <DatePicker
                value={dobFrom}
                onBlur={(dobFrom) => {
                  setDobFrom(formatDate(dobFrom, "MM-DD-YYYY"));
                }}
              />
              <DatePicker
                value={dobTo}
                onBlur={(dobTo) => {
                  setDobTo(formatDate(dobTo, "MM-DD-YYYY"));
                }}
              />
            </div>
          </div>
        </div>
      </fieldset>
      <div className="flex gap-2">
        <Button
          className="bg-white text-xs font-normal sapphire border border-gray-200 py-2 px-4 inline-flex rounded-md w-20 h-8 relative"
          btnText="Apply"
          isDisabled={false}
          onClick={() => {
            setApplyFilter((prevState) => !prevState);
            setFilterView((prevState) => !prevState);
          }}
        />
        <Button
          variant="transparent"
          className="bg-red text-xs font-normal sapphire border border-gray-200 py-2 px-4 inline-flex rounded-md w-20 h-8 relative"
          btnText="Cancel"
          isDisabled={false}
          onClick={() => {
            setDobTo("");
            setDobFrom("");
            setPageNumber(1);
            setFilterView((prevState) => !prevState);
            setSelectedFilterBy(FILTERS_INIT_STATE);
          }}
        />
      </div>
    </div>
  );
};
