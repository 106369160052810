import { useState, useEffect } from "react";
import moment from "moment"
import { InputField } from "Components/Common/InputField/InputField";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { Dropdown } from "Components/Common/Dropdown/Dropdown";
import { GetStates } from "Services/API/GetStates.service";
import { formattedStates } from "Helpers/Providers";
import { formatDate } from "Helpers/DateFormat";

const GENDER_OPTIONS = ["Male", "Female", "Unknown"];

export default function GeneralInfoEdit({ patientDetails, onSave }) {
  const [states, setStates] = useState([]);
  useEffect(() => {
    GetStates()
      .then((res) => {
        //TODO: Make global context for states and move formatter in that
        setStates(formattedStates(res?.data));
      })
      .catch((err) => {
        console.error("[GetStates][API_ERROR] - ", err);
      });
  }, []);
  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="prefix"
          label="Prefix"
          value={patientDetails.prefix}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              prefix: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="first-name"
          label="First Name"
          value={patientDetails.firstName}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              firstName: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="middle-name"
          label="Middle Name"
          value={patientDetails.middleName}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              middleName: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="last-name"
          label="Last Name"
          value={patientDetails.lastName}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              lastName: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="suffix"
          label="Suffix"
          value={patientDetails.suffix}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              suffix: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="email"
          label="Email"
          value={patientDetails.userName}
          isDisabled={true}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <DatePicker
          label="Date of Birth"
          value={moment(patientDetails.DOB, 'MM/DD/YYYY').toDate()}
          onBlur={(birthDate) => {
            onSave({
              ...patientDetails,
              DOB: formatDate(birthDate, "MM/DD/YYYY"),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <Dropdown
          id="select-gender"
          label="Gender"
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              gender: fieldValue,
            });
          }}
        >
          <Dropdown.Option
            id={-1}
            value={patientDetails.gender}
            label=""
            isDefaultOption
          />
          {GENDER_OPTIONS.length > 0 &&
            GENDER_OPTIONS.map((gender, index) => (
              <Dropdown.Option
                key={index}
                id={index}
                value={gender}
                label={gender}
              />
            ))}
        </Dropdown>
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="phone-type"
          label="Phone Type"
          value={patientDetails.phoneType}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              phoneType: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="phone-number"
          label="Phone Number"
          value={patientDetails.phoneNumber}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              phoneNumber: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="address-1"
          label="Address Line 1"
          value={patientDetails.address1}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              address1: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="address-2"
          label="Address Line 2"
          value={patientDetails.address2}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              address2: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="city"
          label="City"
          value={patientDetails.city}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              city: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <Dropdown
          id="select-state"
          label="State"
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              stateId: fieldValue,
            });
          }}
        >
          <Dropdown.Option
            id={-1}
            value={patientDetails.state}
            label=""
            isDefaultOption
          />
          {states.length > 0 &&
            states.map((state, index) => (
              <Dropdown.Option
                key={index}
                id={state.id}
                value={state.id}
                label={state.name}
              />
            ))}
        </Dropdown>
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="country"
          label="Country"
          value={patientDetails.country}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              country: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="zip-code"
          label="ZIP Code"
          value={patientDetails.zipCode}
          onBlur={(fieldValue) => {
            onSave({
              ...patientDetails,
              zipCode: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="membership"
          label="MemberShip"
          value={patientDetails.memberShipPlan}
          isDisabled={true}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="membership"
          label="Patient Id"
          value={patientDetails.cognitoId}
          isDisabled={true}
        />
      </div>
    </div>
  );
}
